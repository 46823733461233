import { Logger } from "@storefront/core/lib/logger";
import { loadFilterPartfinder } from "@storefront/core/data-resolver/filter";
//import store from "@/esf_lommel_dt1_europe/store";

const state = () => ({
  partfinder: {},
  types: [],
  brands: [],
  models: [],
  years: [],
  cilinders: [],
  type: null,
  brand: null,
  model: null,
  year: null,
  cilinder: null,
});

const actions = {
  async loadPartfinder({ commit }) {
    const parts = await loadFilterPartfinder().catch((e) => {
      Logger.error("loadPartfinder", "partfinder.js", e)();
      throw e;
    });

    commit("setPartfinder", parts);
    const types = [];
    parts.types.forEach((element) => {
      if (element.name != "") {
        const type = { value: element.id, text: element.name };
        types.push(type);
      }
    });
    commit("setTypes", types);
  },
  putType({ commit, getters }, { type }) {
    const parts = getters["getPartfinder"];
    const brands = [];
    parts.types.forEach((element) => {
      if (element.id == type) {
        element.brands.forEach((elbrands) => {
          Logger.debug("elbrands", "putType", elbrands)();
          const brand = { value: elbrands.id, text: elbrands.name };
          brands.push(brand);
        });
      }
    });

    //commit("setTypes", []);
    commit("setBrands", brands);
    commit("setModels", []);
    commit("setYears", []);
    commit("setCilinders", []);

    commit("setType", type);
    commit("setBrand", null);
    commit("setModel", null);
    commit("setYear", null);
    commit("setCilinder", null);
  },

  putBrand({ commit, getters }, { brand }) {
    const parts = getters["getPartfinder"];
    const models = [];
    parts.types.forEach((element) => {
      if (element.id == getters["getType"]) {
        element.brands.forEach((elbrands) => {
          if (elbrands.id == brand) {
            elbrands.models.forEach((elmodels) => {
              const model = { value: elmodels.id, text: elmodels.name };
              models.push(model);
            });
          }
        });
      }
    });

    //commit("setTypes", []);
    //commit("setBrands", brands);
    commit("setModels", models);
    commit("setYears", []);
    commit("setCilinders", []);

    //commit("setType", type);
    commit("setBrand", brand);
    commit("setModel", null);
    commit("setYear", null);
    commit("setCilinder", null);
  },

  putModel({ commit, getters }, { model }) {
    Logger.debug("model", "putModel", model)();
    const parts = getters["getPartfinder"];
    Logger.debug("parts", "putModel", parts)();
    const years = [];
    Logger.debug("parts.types", "putModel", parts.types)();
    parts.types.forEach((elm) => {
      if (elm.id == getters["getType"]) {
        Logger.debug("elm", "putModel", elm)();
        elm.brands.forEach((element) => {
          if (element.id == getters["getBrand"]) {
            Logger.debug("element.models", "putModel", element.models)();
            element.models.forEach((elmodels) => {
              if (elmodels.id == model) {
                Logger.debug("elmodels.years", "putModel", elmodels.years)();
                elmodels.years.forEach((elyears) => {
                  const year = { value: elyears.id, text: elyears.name };
                  years.push(year);
                });
              }
            });
          }
        });
      }
    });
    //commit("setTypes", []);
    //commit("setBrands", brands);
    // commit("setModels", models);
    commit("setYears", years);
    commit("setCilinders", []);

    //commit("setType", type);
    // commit("setBrand", brand);
    commit("setModel", model);
    commit("setYear", null);
    commit("setCilinder", null);
  },

  putYear({ commit, getters }, { year }) {
    const parts = getters["getPartfinder"];
    const cilinders = [];
    parts.types.forEach((elm) => {
      if (elm.id == getters["getType"]) {
        elm.brands.forEach((element) => {
          if (element.id == getters["getBrand"]) {
            element.models.forEach((elmodels) => {
              if (elmodels.id == getters["getModel"]) {
                elmodels.years.forEach((elyears) => {
                  if (elyears.id == year) {
                    elyears.cilinders.forEach((elcilinders) => {
                      const cilinder = {
                        value: elcilinders.id,
                        text: elcilinders.name,
                      };
                      cilinders.push(cilinder);
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    //commit("setTypes", []);
    //commit("setBrands", brands);
    // commit("setModels", models);
    // commit("setYears", years);
    commit("setCilinders", cilinders);

    //commit("setType", type);
    // commit("setBrand", brand);
    // commit("setModel", modal);
    commit("setYear", year);
    commit("setCilinder", null);
  },
  putCilinder({ commit }, { cilinder }) {
    commit("setCilinder", cilinder);
  },

  doReset({ commit }) {
    commit("setType", null);
    commit("setBrand", null);
    commit("setYear", null);
    commit("setCilinder", null);
    commit("setBrands", []);
    commit("setModels", []);
    commit("setModel", null);
    commit("setYears", []);
    commit("setCilinders", []);
  },
};

const mutations = {
  setPartfinder(state, payload) {
    state.partfinder = payload;
  },
  setTypes(state, payload) {
    state.types = payload;
  },
  setBrands(state, payload) {
    state.brands = payload;
  },
  setModels(state, payload) {
    state.models = payload;
  },
  setYears(state, payload) {
    state.years = payload;
  },
  setCilinders(state, payload) {
    state.cilinders = payload;
  },
  setType(state, payload) {
    state.type = payload;
  },
  setBrand(state, payload) {
    state.brand = payload;
  },
  setModel(state, payload) {
    state.model = payload;
  },
  setYear(state, payload) {
    state.year = payload;
  },
  setCilinder(state, payload) {
    state.cilinder = payload;
  },
};

const getters = {
  getPartfinder: (state) => state.partfinder,
  getTypes: (state) => state.types,
  getBrands: (state) => state.brands,
  getModels: (state) => state.models,
  getYears: (state) => state.years,
  getCilinders: (state) => state.cilinders,
  getType: (state) => state.type,
  getBrand: (state) => state.brand,
  getModel: (state) => state.model,
  getYear: (state) => state.year,
  getCilinder: (state) => state.cilinder,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
